// Helper function for handling HTTP request errors and sending their info to the alert box
// specialErrors is a list containing any errors that we want to particularly look out for
// so we can send a more readable response to the user
// The format of specialErrors should be a list of lists, where each nested list represents an error
// The first element of the nested list represents the key phrase that should be used to identify the error in the error message
// The second and third element are the title and message for the alert box that pops up (third element is optional)
export let handleError = function(err, addAlert, specialErrors) {
  if(err.response !== undefined) {
    let response = err.response.data;
    if(specialErrors !== undefined) {
      for(let error of specialErrors) {
        if(response.includes(error[0])) {
          addAlert("danger", error[1], error.length === 2 ? "" : error[2]);
          return;
        }
      }
    }
    addAlert("danger", "Unknown Error", response);
  }
  else {
    addAlert("danger", "Unknown Error", err.toString());
  }
}
