import * as React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'

// Form for adding posts to the social media app
function AddPostForm(props) {
  const [username, setUsername] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [postColor, setPostColor] = React.useState(0);
  const [postType, setPostType] = React.useState(0);
  const [formValidated, setFormValidated] = React.useState(false);

  const postColors = ['light', 'dark', 'blue', 'green', 'red', 'yellow'];
  const postColorOptions = postColors.map((color, index) => {
    return {
      value: index,
      label: color[0].toUpperCase() + color.substring(1)
    };
  });

  const postTypes = ['text', 'link', 'image'];
  const postTypeOptions = postTypes.map((type, index) => {
    return {
      value: index,
      label: type[0].toUpperCase() + type.substring(1)
    };
  });

  let resetForm = () => {
    setUsername('');
    setTitle('');
    setContent('');
    setPostColor(0);
    setPostType(0);
  }

  return (
    <Container>
      <Row>
        <Col md={6} className="mx-auto">
          <h3>Submit Post</h3>
          <hr/>
          <Form noValidate validated={formValidated} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            let form = e.currentTarget;
            setFormValidated(true);

            if(form.checkValidity() === true) {
              props.addPost(username, title, content, postColors[postColor], postTypes[postType]);
            }
          }}>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" required value={username} onChange={(e) => {
                setUsername(e.target.value);
              }}/>
              <FormControl.Feedback type="invalid">
                  Please enter a username.
              </FormControl.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Post Title</Form.Label>
              <Form.Control type="text" placeholder="Enter post title" required value={title} onChange={(e) => {
                setTitle(e.target.value);
              }}/>
              <FormControl.Feedback type="invalid">
                  Please enter a title.
              </FormControl.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formContent">
              <Form.Label>Post Content</Form.Label>
              <Form.Control as="textarea" placeholder="Enter post content (add only link if using link or image options) " required value={content} onChange={(e) => {
                setContent(e.target.value);
              }}/>
              <FormControl.Feedback type="invalid">
                  Please enter content.
              </FormControl.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formColor">
              <Form.Label>Post Color</Form.Label>
              <Select
                value={postColorOptions[postColor]}
                options={postColorOptions}
                onChange={(e) => {
                  setPostColor(e.value);
                }}
                isSearchable={false}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Post Type</Form.Label>
              <Select
                value={postTypeOptions[postType]}
                options={postTypeOptions}
                onChange={(e) => {
                  setPostType(e.value);
                }}
                isSearchable={false}
              />
            </Form.Group>
            <Button variant="danger" block style={{float: "left"}} onClick={(e) => {
              resetForm();
            }}>
              Clear
            </Button>
            <Button variant="primary" type="submit" block style={{float: "right"}}>
              Post
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default AddPostForm;
