import React, { Component } from 'react';
import { FaTrashAlt } from "react-icons/fa"
import { BiUpvote, BiDownvote } from "react-icons/bi"
import { AiOutlineHeart, AiOutlineComment, AiOutlineHome } from "react-icons/ai"
import { BsExclamationLg } from "react-icons/bs"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export let DeleteButton = asIconButton(FaTrashAlt);
export let UpvoteButton = asIconButton(BiUpvote);
export let DownvoteButton = asIconButton(BiDownvote);
export let HeartButton = asIconButton(AiOutlineHeart);
export let ExclamationButton = asIconButton(BsExclamationLg);
export let CommentsButton = asIconButton(AiOutlineComment);
export let HomeButton = asIconButton(AiOutlineHome);

// A button that uses a react-icons icon as the button
// This class is borrowed from one of my old projects which is why it uses a React class component 
// instead of the functional components used elsewhere 
export function asIconButton(Icon) {
  return class extends Component {

    constructor(props) {
      super(props);

      this.state = {
        hover: false
      }
    }
  
    getIconStyle(hover, disabled, style) {
      return {
        fontSize: this.props.inLg ? "2rem" : "2.25rem",
        cursor: disabled ? "" : "pointer",
        color: (hover && !disabled) ? "#000000" : "#6c757d",
        margin: "auto 0 auto 0.5rem",
        border: "1px solid",
        backgroundColor: (hover && !disabled) ? "#d3d3d3" : "transparent",
        opacity: disabled ? ".65" : "1.0",
        padding: "0.25rem",
        borderRadius: ".2rem",
        ...style
      } 
    }

    render() {
      let iconButton = (<Icon
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={this.getIconStyle(this.state.hover, this.props.disabled, this.props.style)}
        {...this.props}
      />);

      if(this.props.overlayText == null) {
        return iconButton;
      }

      return (<OverlayTrigger
        overlay={<Tooltip id="tooltip-button-text">{this.props.overlayText}</Tooltip>}
      >
        {iconButton}
      </OverlayTrigger>);
    }
  }
}
