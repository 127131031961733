import * as React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

// Form for adding a comment to a post
export default function AddCommentForm(props) {
  const [comment, setComment] = React.useState('');
  const [formValidated, setFormValidated] = React.useState(false);

  let resetForm = () => {
      setComment('');
  }

  return (
    <Container>
      <Row>
        <Col md={6} className="mx-auto">
          <h3>Submit Comment</h3>
          <small className="text-muted">Comments are fully anonymous and permanent so be careful what you say on the Internet! After adding a comment, refresh to see the comment!</small>
          <hr/>
          <Form noValidate validated={formValidated} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            let form = e.currentTarget;
            setFormValidated(true);

            if(form.checkValidity() === true) {
              props.addComment(comment);
            }
          }}>
            <Form.Group className="mb-3" controlId="formComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control type="text" placeholder="Enter comment" required value={comment} onChange={(e) => {
                setComment(e.target.value);
              }}/>
              <FormControl.Feedback type="invalid">
                  Please enter a comment.
              </FormControl.Feedback>
            </Form.Group>
            <Button variant="danger" block style={{float: "left"}} onClick={(e) => {
              resetForm();
            }}>
              Clear
            </Button>
            <Button variant="primary" type="submit" block style={{float: "right"}}>
              Post
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
