import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import axios from "axios";

import AddPostForm from "./components/AddPostForm";
import PostsList from "./components/PostsList";
import PostFullView from "./components/PostPreview";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { AlertContainer } from "react-bs-notifier";
import { handleError } from "./ErrorUtils";

import "bootstrap/dist/css/bootstrap.min.css";

const httpRequest = axios.create({
  withCredentials: true
});

let StyledAlert = require("./components/util/AlertStyle").StyledAlert;

const SERVER_URL = "https://posts-worker.rychang1205.workers.dev/";

function App() {
  const [posts, setPosts] = React.useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [sortType, setSortType] = React.useState(0);

  let addAlert = (type, headline, message) => {
    let alertsCopy = alerts.slice();
    alertsCopy.push({
      id: new Date().getTime(),
      type: type,
      headline: headline,
      message: message,
    });

    if (alertsCopy.length > 4) {
      alertsCopy.shift();
    }

    setAlerts(alertsCopy);
  };

  // Retrieve all posts from the API
  React.useEffect(() => {
    httpRequest
      .get(SERVER_URL + "posts")
      .then((response) => {
        setPosts(response.data);
        addAlert("success", "Successfully retrieved posts");
      })
      .catch((error) => {
        handleError(error, addAlert);
      });
  }, []);

  let addPost = (username, title, content, postColor, postType) => {
    addAlert("info", "Adding post...");
    httpRequest
      .post(SERVER_URL + "posts", {
        username: username,
        title: title,
        content: content,
        postColor: postColor,
        postType: postType,
      })
      .then((response) => {
        let postId = response.data.id;

        let newPostsList = posts.slice();
        newPostsList.push({
          username: username,
          title: title,
          content: content,
          timestamp: Date.now(),
          postColor: postColor,
          postType: postType,
          numUpvotes: 0,
          numDownvotes: 0,
          numHearts: 0,
          numExclamationPoints: 0,
          comments: [],
          id: postId,
        });
        setPosts(newPostsList);

        addAlert("success", "Successfully added post");
      })
      .catch((error) => {
        handleError(error, addAlert, [["authenticate", "Unauthorized Access", "You are not authorized as this user!"]]);
      });
  };

  let getPost = async (id) => {
    addAlert("info", "Retrieving post...");
    try {
      let response = await httpRequest.get(SERVER_URL + "posts/" + id);
      addAlert("info", "Retrieved post");
      return response.data;
    }
    catch(error) {
      handleError(error, addAlert);
    }
  }

  let deletePost = (id) => {
    addAlert("info", "Deleting post...");
    httpRequest
      .delete(SERVER_URL + "posts/" + id)
      .then((response) => {
        let newPostsList = posts.slice();
        for (let i = 0; i < newPostsList.length; i++) {
          if (newPostsList[i].id === id) {
            newPostsList.splice(i, 1);
          }
        }
        setPosts(newPostsList);

        addAlert("success", "Successfully deleted post");
      })
      .catch((error) => {
        handleError(error, addAlert, [["authenticate", "Unauthorized Access", "You are not the creator of this post!"]]);
      });
  };

  // Increments the count for one of the emojis on a post
  let incrementCount = (id, emoji) => {
    addAlert("info", "Incrementing Vote...");
    httpRequest
      .post(SERVER_URL + "posts/increment/" + id + "/" + emoji)
      .then((response) => {
        let newPostsList = posts.slice();
        for (let i = 0; i < newPostsList.length; i++) {
          if (newPostsList[i].id === id) {
            switch (emoji) {
              case "upvote":
                newPostsList[i].numUpvotes++;
                break;
              case "downvote":
                newPostsList[i].numDownvotes++;
                break;
              case "heart":
                newPostsList[i].numHearts++;
                break;
              case "exclamation":
                newPostsList[i].numExclamationPoints++;
                break;
              default:
                break;
            }
          }
        }
        setPosts(newPostsList);

        addAlert("success", "Successfully incremented vote");
      })
      .catch((error) => {
        handleError(error, addAlert);
      });
  };

  let addComment = (id, comment) => {
    addAlert("info", "Adding Comment...");
    httpRequest
      .post(SERVER_URL + "posts/comment/" + id, {
        comment: comment
      })
      .then((response) => {
        let newPostsList = posts.slice();
        for (let i = 0; i < newPostsList.length; i++) {
          if (newPostsList[i].id === id) {
            newPostsList[i].comments.push(comment);
          }
        }
        setPosts(newPostsList);

        addAlert("success", "Successfully added comment");
      })
      .catch((error) => {
        handleError(error, addAlert);
      });
  }

  let sortTypes = ["Most Recent", "Least Recent", "Best Voted", "Worst Voted"];
  const sortTypeOptions = sortTypes.map((type, index) => {
    return {
      value: index,
      label: type,
    };
  });

  let sortedPosts = posts.slice();
  sortedPosts.sort((a, b) => {
    switch (sortTypes[sortType]) {
      case "Most Recent":
        return a.timestamp < b.timestamp;
      case "Least Recent":
        return a.timestamp > b.timestamp;
      case "Best Voted":
        return a.numUpvotes - a.numDownvotes < b.numUpvotes - b.numDownvotes;
      case "Least Voted":
        return a.numUpvotes - a.numDownvotes > b.numUpvotes - b.numDownvotes;
      default:
        return a.timestamp < b.timestamp;
    }
  });

  return (
    <div className="App">
      <Container>
        <Row>
          <Col className="mx-auto">
            <h1 className="display-5 my-3 text-center">
              Cloudflare Social Media Posts
            </h1>
            <h6 className="text-secondary text-center">
              New posts may take up to a minute to be updated on
              server
            </h6>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Router>
            <Route path="/" exact>
              <Container>
                <Row>
                  <Col lg={6} className="order-lg-last mb-5">
                    <AddPostForm addPost={addPost} />
                  </Col>
                  <Col lg={6}>
                    <hr className="d-lg-none"/>
                    <Select
                      value={sortTypeOptions[sortType]}
                      options={sortTypeOptions}
                      onChange={(e) => {
                        setSortType(e.value);
                      }}
                      isSearchable={false}
                    />
                    <PostsList
                      posts={sortedPosts}
                      deletePost={deletePost}
                      incrementCount={incrementCount}
                    />
                  </Col>
                </Row>
              </Container>
            </Route>
            <Route path="/posts/:id" render={(matchProps) =>
              <PostFullView
                {...matchProps}
                getPost={getPost}
                deletePost={deletePost}
                incrementCount={incrementCount}
                addComment={addComment}
              />
            }/>
          </Router>
        </Row>
      </Container>
      <AlertContainer position="bottom-right">
        {alerts.length > 0 && (
          <Button
            className="mb-1"
            variant="danger"
            onClick={() => {
              setAlerts([]);
            }}
          >
            Clear All Alerts
          </Button>
        )}
        {alerts.map((alert) => {
          return (
            <StyledAlert
              timeout={10000}
              onDismiss={() => {
                let i = alerts.indexOf(alert);
                if (i < 0) return;

                let alertsCopy = alerts.slice();
                alertsCopy.splice(i, 1);

                setAlerts(alertsCopy);
              }}
              type={alert.type}
              key={alert.id}
              headline={alert.headline}
            >
              {alert.message}
            </StyledAlert>
          );
        })}
      </AlertContainer>
    </div>
  );
}

export default App;
