import * as React from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { DeleteButton, UpvoteButton, DownvoteButton, HeartButton, ExclamationButton, CommentsButton } from './IconButton'
import AddCommentForm from "./AddCommentForm";
import withRedirect from './util/WithRedirect'

// A post on the social media board that is rendered using a Bootstrap Card
function Post(props) {
  let postData = props.postData;

  let date = new Date();
  date.setTime(postData.timestamp);

  let postColor = postData.postColor;
  let postColorClassBg; // class that is used to apply post color
  let postColorClassTx; // class for styling the psot text color to match the post color
  switch(postColor) {
    case 'blue':
      postColorClassBg = "bg-primary";
      postColorClassTx = "text-white";
      break;
    case 'red':
      postColorClassBg = "bg-danger";
      postColorClassTx = "text-white";
      break;
    case 'green':
      postColorClassBg = "bg-success";
      postColorClassTx = "text-white";
      break;
    case 'yellow':
      postColorClassBg = "bg-warning";
      postColorClassTx = "";
      break;
    case 'dark':
      postColorClassBg = "bg-dark";
      postColorClassTx = "text-white";
      break;
    case 'light':
    default:
      postColorClassBg = "bg-light";
      postColorClassTx = "";
      break;
  }

  let footer = (
    <Card.Footer>
      <Row>
        <Col xs={6}>
          <small className={postColorClassTx}>
            Posted by {postData.username} at {date.toLocaleString()}
          </small>
        </Col>
        <Col xs={6}>
          <DeleteButton onClick={props.deletePost} className={"float-end " + postColorClassBg + " " + postColorClassTx}/>
          {props.fullview == null && 
            <React.Fragment>
              <span className="float-end mx-2"> {postData.comments.length} </span>
              <CommentsButton onClick={() => {
                props.setRedirect('/posts/' + postData.id);
              }} className={"float-end " + postColorClassBg + " " + postColorClassTx}/>
            </React.Fragment>
          }
        </Col>
      </Row>
      <hr></hr>
      <UpvoteButton onClick={() => { 
        props.incrementCount('upvote');
      }}  className={postColorClassBg + " " + postColorClassTx}/>
      <span> {postData.numUpvotes} </span>

      <DownvoteButton onClick={() => {
        props.incrementCount('downvote');
      }}  className={postColorClassBg + " " + postColorClassTx}/>
      <span> {postData.numDownvotes} </span>

      <HeartButton onClick={() => {
        props.incrementCount('heart');
      }}  className={postColorClassBg + " " + postColorClassTx}/>
      <span> {postData.numHearts} </span>

      <ExclamationButton onClick={() => {
        props.incrementCount('exclamation');
      }} className={postColorClassBg + " " + postColorClassTx}/>
      <span> {postData.numExclamationPoints} </span>

      <span className="float-end">
        {"Score: " + ((postData.numUpvotes > postData.numDownvotes) ? "+" : "") + (postData.numUpvotes - postData.numDownvotes)}
      </span>
    </Card.Footer>);

  let content;
  switch(postData.postType) {
    case 'link':
      content = 
        (<Card className={"mx-auto " + postColorClassBg + " " + postColorClassTx}>
          <Card.Header>
            <h3><a href={postData.content}>{postData.title}</a></h3>
          </Card.Header>
          {footer}
        </Card>);
      break;
    case 'image':
      content = 
        (<Card className={"mx-auto " + postColorClassBg + " " + postColorClassTx}>
          <Card.Header>
            <h3>{postData.title}</h3>
          </Card.Header>
          <Card.Body>
            <img src={postData.content} alt={postData.title} className="mw-100"></img>
          </Card.Body>
          {footer}
        </Card>);
      break;
    case 'text':
    default:
      content = 
        (<Card className={"mx-auto " + postColorClassBg + " " + postColorClassTx}>
          <Card.Header>
            <h3>{postData.title}</h3>
          </Card.Header>
          <Card.Body>
            <p>{postData.content}</p>
          </Card.Body>
          {footer}
        </Card>);
      break;
  }

  let comments;
  if(postData.comments === undefined) {
    comments = (
      <Row>
        <h3 className="text-center text-muted">Comments are not enabled for these older posts. Any new posts should have comments enabled!</h3>
      </Row>
    );
  }
  else if(props.fullview !== undefined) {
    comments = (
      <Row>
        <Col lg={6} className="order-lg-last mb-5">
          <AddCommentForm addComment={props.addComment} />
        </Col>
        <Col lg={6}>
          <h2 className="text-center">Comments</h2>
          { postData.comments.length === 0 && <h4 className="text-center text-muted">No comments</h4> }
          { postData.comments.map((comment) => {
            return (
              <Card className="mt-3">
                <Card.Body>
                  {comment}
                </Card.Body>
              </Card>
            );
          }) }
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <Row className="mb-5">
        <Col md={9} className="mx-auto">
          { content }
        </Col>
      </Row>
      {comments}
    </React.Fragment>
  );
}

export default withRedirect(Post);
