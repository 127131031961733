import * as React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import withRedirect from './util/WithRedirect'

import Post from "./Post"
import { HomeButton } from "./IconButton"

// A complete view of the post with its comments
function PostFullView(props) {
  const [postData, setPostData] = React.useState({});

  let id = props.match.params.id;
  
  React.useEffect(() => {
    async function fetchData() {
      let newPostData = await props.getPost(id);
      setPostData(newPostData);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="mb-3 d-flex justify-content-center">
          <HomeButton onClick={() => {
            props.setRedirect("/");
          }}/>
        </Col>
      </Row>
      <Post
        postData={postData}
        deletePost={() => {
          props.deletePost(id);
        }}
        incrementCount={(emoji) => {
          props.incrementCount(id, emoji);
        }}
        addComment={(comment) => {
          props.addComment(id, comment);
        }}
        fullview
      />
    </Container>
  );
}

export default withRedirect(PostFullView);
