import * as React from "react";

import Container from 'react-bootstrap/Container';

import Post from './Post'
  
// Collection of posts organized in a list
function PostsList(props) {
  let postsList = props.posts.map((post) => {
    return <Post
      postData={post}
      key={post.id}
      deletePost={() => {
        props.deletePost(post.id);
      }}
      incrementCount={(emoji) => {
        props.incrementCount(post.id, emoji);
      }}
    />
  });

  return (
    <Container className="mt-3">
      {postsList}
    </Container>
  );
}

export default PostsList;
